<template>
    <div class="grid">
        <div class="col-12">
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <div class="font-medium text-xl text-900 mb-3">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="usuarios-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                    </div>
                    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                    <Toast />
                    <div class="">
                        <div class="grid">
                            <div class="field col-6">
                                <label for="name">Nome</label>
                                <InputText
                                    id="name"
                                    v-model.trim="form.name"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    placeholder="Informe o nome do usuario"
                                    :class="{ 'p-invalid': submitted && !form.name }"
                                />
                                <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="phone">Telefone</label>
                                <InputMask
                                    id="phone"
                                    mask="(99) 99999-999?9"
                                    v-model.trim="form.phone"
                                    required="true"
                                    autocomplete="off"
                                    placeholder="Informe o telefone do usuário"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="phone">E-mail</label>
                                <InputText
                                    id="emailSolicitante"
                                    v-model.trim="form.email"
                                    placeholder="Informe o e-mail do usuario"
                                    required="true"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.email }"
                                />
                                <small class="p-error" v-if="submitted && !form.email">E-mail é obrigatório.</small>
                            </div>
                            <div class="field col-6">
                                <label for="password">Senha</label>
                                <InputText
                                    id="password"
                                    type="password"
                                    v-model="form.password"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.password }"
                                />
                                <small class="p-error" v-if="submitted && !form.password">Senha é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="plataformaOrigem">Plataforma de acesso</label>
                                <Dropdown
                                    id="binary"
                                    :disabled="!form.userCustomers"
                                    v-model="form.plataformaOrigem"
                                    :options="plataformaOrigem"
                                    optionLabel="label"
                                    optionValue="value"
                                />
                            </div>
                            <div class="field col-6">
                                <label for="tokenSoc">Token SOC</label>
                                <InputText
                                    id="tokenSoc"
                                    v-model.trim="form.tokenSoc"
                                    placeholder="Informe o token para acesso ao SOC"
                                    required="true"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="profile">Perfil</label>
                                <Dropdown
                                    id="profile"
                                    v-model="form.profileId"
                                    :options="profiles"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Escolha um perfil"
                                    :class="{ 'p-invalid': submitted && !form.profileId }"
                                />
                                <small class="p-error" v-if="submitted && !form.profileId">Seleção obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="grupos">Grupo</label>
                                <DropDownGrupo v-model="grupo" :idValue="form.idGrupo"></DropDownGrupo>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="companies">Bases</label>
                                <MultiSelect
                                    id="companies"
                                    v-model="form.companies"
                                    :options="companies"
                                    optionLabel="name"
                                    dataKey="id"
                                    placeholder="Sem base específica"
                                    @change="onChangeBases"
                                    :class="{ 'p-invalid': submitted && !form.companies }"
                                />
                                <small class="p-error" v-if="submitted && !form.companies">Seleção obrigatória.</small>
                            </div>
                            <div class="field col-6">
                                <label for="defaultCompany">Base principal</label>
                                <Dropdown
                                    id="defaultCompany"
                                    v-model="form.defaultCompany"
                                    :options="form.companies"
                                    optionLabel="name"
                                    dataKey="id"
                                    placeholder="Sem base específica"
                                    :disabled="!form.companies?.length"
                                    :class="{ 'p-invalid': submitted && !form.defaultCompany }"
                                />
                                <small class="p-error" v-if="submitted && !form.defaultCompany">Seleção obrigatória.</small>
                            </div>
                        </div>
                        <div class="field">
                            <label for="customers">Clientes</label>
                            <MultiSelect
                                id="customers"
                                placeholder="Selecione..."
                                :filter="true"
                                @filter="loadCustomers"
                                @change="onChangeCustomer"
                                v-model="form.userCustomers"
                                :maxSelectedLabels="10"
                                :virtualScrollerOptions="{ itemSize: 60 }"
                                :options="customers"
                                optionLabel="name"
                                :showToggleAll="false"
                                :filterFields="['name', 'code']"
                                dataKey="id"
                                :disabled="!form.companies?.length"
                            >
                                <template #option="{ option }">
                                    <div>
                                        {{ option.name + (option.integracaoSoc ? ' | CodSoc - ' + option.code : '') }}
                                    </div>
                                </template>
                            </MultiSelect>
                        </div>
                        <div class="field">
                            <label for="branches">Unidades</label>
                            <MultiSelect
                                id="branches"
                                placeholder="Selecione..."
                                v-model="form.userCustomerBranches"
                                :filter="true"
                                @filter="loadBranches"
                                display="chip"
                                :options="branches"
                                :disabled="!form.userCustomers?.length"
                                optionLabel="name"
                                dataKey="id"
                                :showToggleAll="false"
                            />
                        </div>
                        <Divider align="center">
                            <span class="p-tag">Método de Agendamento</span>
                        </Divider>
                        <div class="grid">
                            <div class="field col-6">
                                <SelectButton
                                    id="binary"
                                    :disabled="!form.userCustomers"
                                    v-model="form.metodoAgendamento"
                                    :options="metodoAgendamento"
                                    @change="quemAgendaAvisa"
                                    optionLabel="label"
                                    optionValue="value"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="quemAgenda">Quem agenda</label>
                                <Dropdown
                                    id="quemAgenda"
                                    v-model="form.quemAgenda"
                                    :disabled="form.metodoAgendamento != 2"
                                    :options="agendaAvisa"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione quem agenda"
                                ></Dropdown>
                            </div>
                            <div class="field col-6">
                                <label for="quemAvisa">Quem avisa</label>
                                <Dropdown
                                    id="quemAvisa"
                                    v-model="form.quemAvisa"
                                    :disabled="form.metodoAgendamento != 2"
                                    :options="agendaAvisa"
                                    optionValue="value"
                                    optionLabel="label"
                                    placeholder="Selecione quem avisa"
                                ></Dropdown>
                            </div>
                        </div>
                        <Divider align="center">
                            <span class="p-tag">Plataforma RH</span>
                        </Divider>
                        <div class="grid">
                            <div class="field col-6">
                                <div class="field-checkbox mb-2">
                                    <InputSwitch v-model="form.acessoTodosOsClientes" inputId="acessoTodosOsClientes" />
                                    <label class="mr-2 mb-1" for="enviarComprovante">Acesso a todos os clientes</label>
                                </div>
                                <div class="field-checkbox mb-2">
                                    <InputSwitch v-model="form.acessoTodasAsUnidades" inputId="acessoTodasAsUnidades" />
                                    <label class="mr-2 mb-1" for="enviarComprovante">Acesso a todas as unidades</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/users')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import { nomeEmpresaUsuarioReduzido } from '../../services/auth';
import QuemAgendaAvisa from '../../enums/QuemAgendaAvisa';
import DropDownGrupo from '../grupos/components/dropdown-grupos.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

const PADRAO_CLIENTE = 1;
const DINAMICO = 2;
export default {
    components: { DropDownGrupo, AppInfoManual },
    data() {
        return {
            form: {},
            loading: false,
            submitted: false,
            grupos: [],
            grupo: undefined,
            profiles: [],
            agendaAvisa: [],
            types: [
                { label: 'Gerente', value: '001' },
                { label: 'Coordenador', value: '002' }
            ],
            metodoAgendamento: [
                { label: 'Padrão cliente', value: 1 },
                { label: 'Dinamico', value: 2 }
            ],
            plataformaOrigem: [
                { label: 'DUON', value: 'DUON' },
                { label: 'PLATAFORMA RH', value: 'PLATAFORMA_RH' }
            ],
            companies: [],
            customers: [],
            branches: [],
            phone: ''
        };
    },
    async mounted() {
        this.$service = new BaseService('/users');
        this.$serviceProfiles = new BaseService('/profiles');
        this.$serviceCompany = new BaseService('/companies/companies-by-user/combo');
        this.$serviceCliente = new BaseService('/customers/findall-semfiltro-tenant');
        this.$serviceCustomerBranch = new BaseService('/customer/branches/combo');
        this.$serviceGrupos = new BaseService('/grupos');
        this.loading = true;
        try {
            await this.load();
            await Promise.all([this.loadProfiles(), this.loadCompanies(), this.loadCustomers(), this.loadBranches(), this.loadGrupos()]);
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar usuário' : 'Adicionar usuário';
        }
    },
    watch: {
        grupo() {
            this.form.idGrupo = this.grupo?.id ?? null;
        },
        'form.companies'() {
            if (this.form?.companies?.length == 0) {
                this.form.companies = null;
            }
        }
    },
    methods: {
        async save() {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Campos obrigatórios não preenchidos!', life: 3000 });
                return;
            }
            if (!this.form.password) {
                delete this.form.password;
            }
            try {
                const formatForm = {
                    ...this.form,
                    companies: this.form.companies?.map((company) => ({ company })),
                    userCustomers: this.form.userCustomers?.map((customer) => ({ customer })),
                    userCustomerBranches: this.form.userCustomerBranches?.map((branch) => ({ branch }))
                };

                const { data } = await this.$service.save(formatForm);
                if (!data?.success) {
                    this.$toast.add({ severity: 'error', summary: data.message, life: 3000 });
                    return;
                }
                this.$toast.add({ severity: 'success', summary: 'Usuário salvo com sucesso!', life: 3000 });
                this.$router.replace('list');
            } catch (error) {
                if (error.response?.data?.statusCode === 422) {
                    this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
                    return;
                }
                if (error.response?.data?.statusCode === 400) {
                    this.$toast.add({ severity: 'warn', summary: error.response?.data?.message || error.message, life: 3000 });
                    return;
                }
            }
        },
        hasError() {
            this.submitted = true;
            return !!(!this.form.name || !this.form.email || !this.form.companies || !this.form.defaultCompany || !this.form.profileId);
        },
        onChangeBases() {
            this.form.userCustomers = [];
            this.onChangeCustomer();
            this.quemAgendaAvisa();
            this.loadCustomers();
        },
        onChangeCustomer() {
            this.form.userCustomerBranches = [];
            this.loadBranches();
        },
        async quemAgendaAvisa() {
            if (this.form.metodoAgendamento != DINAMICO) {
                this.agendaAvisa = [];
                this.form.quemAgenda = null;
                this.form.quemAvisa = null;
            } else {
                await this.loadScheduleNotify();
                this.form.quemAgenda = this.form?.customer?.quemAgenda;
                this.form.quemAvisa = this.form?.customer?.quemAvisa;
            }
        },
        async load() {
            if (this.$route.params.id) {
                this.loadScheduleNotify();

                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
                this.form.customerId = data?.id ?? null;
                this.form.metodoAgendamento = this.form.quemAgenda || this.form.quemAvisa ? DINAMICO : PADRAO_CLIENTE;

                this.form.companies = data.companies?.map((c) => c.company);
                this.form.userCustomers = data.userCustomers?.map((uc) => uc.customer);
                this.form.userCustomerBranches = data.userCustomerBranches?.map((ucb) => ucb.branch);
            }
        },
        async loadScheduleNotify() {
            const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(38);
            this.agendaAvisa = [
                { label: 'Solicitante', value: QuemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA },
                { label: 'Usuário Escolhe', value: QuemAgendaAvisa.USUARIO_ESCOLHE }
            ];
        },
        async loadProfiles() {
            const { data } = await this.$serviceProfiles.findAll({ page: 1, limit: 9999 });
            this.profiles = data.items;
        },
        async loadCompanies() {
            const { data } = await this.$serviceCompany.findAll({});
            this.companies = data;
        },
        async loadCustomers(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$serviceCliente.findAll({ limit: 9999, page: 1, filter: event?.value, companyId });
            this.customers = data.items;
        },
        async loadBranches() {
            const customerId = this.form.userCustomers?.map((uc) => uc.id);
            const { data } = await this.$serviceCustomerBranch.findAll({ limit: 9999, page: 1, filter: this.filter, customerId });
            this.branches = data;
        },
        async loadGrupos() {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$serviceGrupos.findAll({ limit: 9999, page: 1, filter: this.filter, companyId });
            this.grupos = data.items;
        }
    }
};
</script>

<style scoped lang="scss">
#companies {
    padding: 0.3rem 0.5rem;
}
</style>
