<template>
    <AppOnePageDropdown 
        v-model="value"
        :idValue="idValue"
        optionLabel="descricao"
        optionValue="id"
        :filterFields="['descricao']"
        placeholder="Selecione o grupo"
        :service="$service"
        :itemSize="itemSize"
        :filtrosExtras="{ ativo: true }"
        >
        
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        }
    },    
    data() {
        return {
            $service: null
        }
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/grupos');
    }
};
</script>